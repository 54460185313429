import {Trans, useTranslation} from "ni18n";
import React from "react";
import {useTypedSelector} from "src/store";
import useMediaQuery from "src/useMediaQuery";
import {V5Image, useV5ImgSrc} from "src/useV5ImgSrc";

import {v5Pages} from "../../_common/_constants";
import {Btn} from "../V5Comp";

const Insurances: React.VoidFunctionComponent = () => {
  const backgroundImage = useV5ImgSrc("home/insurances/01");
  const {isMobile} = useTypedSelector(state => state.config);

  const imageSource = backgroundImage;
  const i18n = useTranslation();
  const isSm = useMediaQuery("sm");

  const showSm = isSm || isMobile;

  return (
    <section
      aria-labelledby="insurance-heading"
      className={`${showSm ? "pv16 pv8-md" : "pt32 pb120 pv20-sm"} bgnr bgcov bg-gray100`}
      style={{
        backgroundImage: showSm ? "" : `url(${imageSource})`,
        backgroundPosition: "80% 50%",
      }}
    >
      <div className={`maxw85 ph8 ${isMobile ? "minh0" : "minh52"} ma`}>
        <div className="maxw48">
          <h2
            id="insurance-heading"
            className="op1 gray800 mb4 mb2-lg mt0 fs12 lh18 font-ml ls1 ttu"
          >
            {i18n.t("Insurance and pricing")}
          </h2>
          <strong className="mb4 fs28-md lh12 maxw30-md font-cm fs40 db maxw40-xl">
            <Trans>
              We accept all major insurance plans.{" "}
              <em className="darkBlue fsn">No subscriptions or membership fees.</em>
            </Trans>
          </strong>
          <Btn
            href={v5Pages.insurance}
            className="event-pUU1qz brd2nc-f pv3-f brd-black-f black-f fs12-f mb8"
            bg="transparent"
            aria-label={i18n.t("Learn More about insurance and pricing")}
          >
            {i18n.t("Learn more")}
          </Btn>
        </div>
      </div>
      {showSm && (
        <V5Image
          className="w100p"
          src="home/insurances/01-mobile"
          alt={i18n.t(
            "We accept AARP, United Healthcare, Blue Shield of California and many more. Easily scan your insurance card in our mobile app.",
          )}
        />
      )}
    </section>
  );
};

export default Insurances;
